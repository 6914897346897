import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import DateGateway from "../components/DateGateway"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="👰🤵 Welcome"
          description="You have found the website for the Elliot & Towle wedding. We can't wait to celebrate with you."
        />
        <DateGateway />
      </Layout>
    )
  }
}
export default IndexPage
