import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

class DateGateway extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      day: null,
      month: null,
      year: null,
      isDateCorrect: null,
      isDateNotCorrect: null,
    }
    this.validateDate = this.validateDate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  validateDate() {
    const isInputCorrect =
      this.state.day === 20 &&
      this.state.month === 7 &&
      this.state.year === 2024
        ? true
        : false
    this.setState({
      isDateCorrect: isInputCorrect,
    })
  }
  handleInputChange = event => {
    const target = event.target
    // const value = parseInt(target.value,10) < 10 ? target.value='0' + target.value : target.value;
    const value = parseInt(target.value, 10)
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      this.validateDate
    )
  }
  handleSubmit = event => {
    event.preventDefault()

    this.setState(
      {
        isDateNotCorrect: this.state.isDateCorrect ? false : true,
      },
      this.validateDate
    )

    // navigate('/welcome');
  }
  render() {
    return (
      <div className="date-gateway bg-primary text-secondary flex items-center justify-center my-auto md:py-20">
        <div className="container-fluid flex justify-center">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <h1 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl mb-2 md:mb-12 xs:px-2">
              Welcome
            </h1>
            <p className="text-xl xs:px-2">
              Please enter the date of our wedding to enter.
            </p>
            <p className="text-xl xs:px-2">
              The date can be found on your invitation.
            </p>

            <form
              method="post"
              action="/welcome"
              name="gateway"
              className="gateway__form w-full flex flex-wrap items-center mt-10 lg:mt-14"
              onSubmit={this.handleSubmit}
            >
              <p className="w-1/2 md:w-auto pr-2 xs:px-2">
                <label htmlFor="day">
                  <span className="label flex w-full text-2xl font-normal mb-1">
                    Day*
                  </span>
                  <input
                    type="number"
                    name="day"
                    min="1"
                    max="31"
                    step="1"
                    placeholder="00"
                    value={this.state.day || ""}
                    onChange={e => this.handleInputChange(e)}
                    className="input flex w-full mb-4 pl-2 py-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary text-3xl xl:text-4xl focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                  />
                </label>
              </p>
              <p className="w-1/2 md:w-auto pl-2 xs:px-2">
                <label htmlFor="month">
                  <span className="label flex w-full text-2xl font-normal mb-1">
                    Month*
                  </span>
                  <input
                    type="number"
                    name="month"
                    min="1"
                    max="12"
                    step="1"
                    placeholder="00"
                    value={this.state.month || ""}
                    onChange={e => this.handleInputChange(e)}
                    className="input flex w-full mb-4 pl-2 py-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary text-3xl xl:text-4xl focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                  />
                </label>
              </p>
              <p className="w-1/2 md:w-auto pr-2 xs:px-2">
                <label htmlFor="day">
                  <span className="label flex w-full text-2xl font-normal mb-1">
                    Year*
                  </span>
                  <input
                    type="number"
                    name="year"
                    min="2020"
                    max="2030"
                    step="1"
                    placeholder="0000"
                    value={this.state.year || ""}
                    onChange={e => this.handleInputChange(e)}
                    className="input flex w-full mb-4 pl-2 py-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary  text-3xl xl:text-4xl focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                  />
                </label>
              </p>
              <p className="w-1/2 md:w-auto pl-2 xs:px-2 mt-auto mb-4">
                {this.state.isDateCorrect === true ? (
                  <AniLink
                    cover
                    to="/welcome"
                    bg="#4D6D69"
                    entry={{ delay: 0.5 }}
                    duration={1}
                    className="gateway__submit w-full inline-block text-2xl font-normal text-center text-black bg-secondary border border-secondary border-solid mb-3 px-4 py-3 hover:text-tertiary hover:bg-transparent hover:border-tertiary focus:text-tertiary focus:bg-transparent focus:border-tertiary focus:outline-none"
                  >
                    Enter
                  </AniLink>
                ) : (
                  <button
                    type="submit"
                    className="gateway__submit w-full inline-block text-2xl font-normal text-center text-black bg-secondary border border-secondary border-solid mb-3 px-4 py-3 hover:text-tertiary hover:bg-transparent hover:border-tertiary focus:text-tertiary focus:bg-transparent focus:border-tertiary focus:outline-none"
                  >
                    Enter
                  </button>
                )}
              </p>
            </form>
            {this.state.isDateNotCorrect === true && (
              <p className="text-xl xs:px-2">
                If you're having trouble please contact us via{" "}
                <a
                  href="mailto:hollyelliott@hotmail.com"
                  title="Email us"
                  className="underline hover:underline"
                >
                  email
                </a>
                .
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default DateGateway
